$fontcolor: #503c10;

.LeftLanding {
  border-right-width: 5px;
  height: 100vh;
  .dynamicLink {
    color: white !important;
    :hover{
      color: white !important;
 
    }
  }
  .icon {
    width: 28px;
    height: 28px;
    padding: 2px;
    cursor: pointer;
    :active {
      color: black;
    }
  }
  .icon1 {
    width: 20px;
    height: 20px;
    padding: 1px;
    margin-top: 1px !important;
    cursor: pointer;
    :active {
      color: black;
    }
  }
  .secondContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    h5{
      margin: 0px !important;
      margin-top: 5px !important;
    }
    .logoimg {
      width: 160px;
      height: 160px;
      // margin-top: 2rem;
      .logo {
        width: 160px;
        height: 160px;
      }
    }
    .header {
      margin-top: 1rem;
      margin-bottom: 0.1rem;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      color: $fontcolor;
    }
    .header2 {
      color: $fontcolor;
      margin-top: 1rem !important;
    }
    .startForm {
      display: flex;
      flex-direction: column;
      text-align: center;
      .label {
        margin-top: 0.5rem;
      }
    }
    .btncontainer {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .socialBtns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 1rem;
    }
  }
}
.rightLanding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 25%;
  .logo {
    width: 160px;
    height: 160px;
    margin: 0;
    padding: 0;
  }
  .lastHead {
    display: flex;
    width: 300%;
    text-align: center;
    padding-top: 10px;
  }
}
.LocationModalLine {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.LocationPin {
  width: 16px;
  margin-top: 16px;
  cursor: pointer;
}
.locationHeader {
  margin-bottom: 1rem;
}
.logosmall {
  width: 100px;
  height: 100px;
}
.btnsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  .btnsWrapperItem {
    padding: 10px 90px;
    cursor: pointer;
  }
}
.socialBtns2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .icon3 {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-top: 16px;
  }
}
.branchNameIntab {
  margin-top: 16px;
}
.locationHeaderCenter {
  text-align: center;
}
.btnClick {
  color: red;
  margin-top: 1rem;
  :hover,
  :active {
    color: black !important;
  }
}
.react-time-picker{
  margin-top: 2rem;
}