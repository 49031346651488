.cardimg {
  height: 10rem;
  width:300px
}
.cardimg2{
  width: 365px !important;
}

@media (min-width: 767.98px) {
  .cardimg {
    width: 400px;
    // height: 7rem;
  }
  .cardimg2{
    width: 300px;
  }
}

@media (max-width: 991.98px) {
  .cardimg {
    width: 300px;
    height: 7rem;
  }
 
  .cardimg2{
    width: 400px;
    height: 13rem;
  }

  .container-with-dots {
    margin-bottom: 4rem;
  }
  .css-z070eg-MuiCardContent-root:last-child{
    padding: 10px !important;
  }
}

@media (max-width: 767.98px) {
  .cardimg {
    height: 7rem;
  }
  .cardimg2{
    width: 550px;
    height: 15rem;
  }
  .container-with-dots {
    margin-bottom: 4rem;
  }
}

.ImgContainer {
  @media (max-width: 980px) {
    margin-top: 6rem;
  }
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
  .bigImage {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 470px;
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    @media (max-width: 991px) {
      height: 200px;
    }

    ::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
  .overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    .titleanddesc {
      display: flex;
      flex-direction: column;
      margin-top: 10rem;
      .title,
      .desc {
        color: white;
        z-index: 1;
      }
    }
  }
}
.container-with-dots {
  height: 40vh;
  margin-top: 1rem;
  // margin-bottom: 5rem;
  @media (max-width: 991px) {
    height: 20vh;
  }
}
.owl-nav {
  display: none;
}
.carrosel-item {
  @media (max-width: 991px) {
    .cardimg {
      margin-bottom: 0px;
      height: 1rem;
    }
    .cardtxt {
      font-size: 12px;
    }
  }
}
.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.6rem;
  margin-left: 0.5rem;
  .countertxt {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 20px;
  }
}

.notitle {
  margin-bottom: 3rem;
}

.owl-card {
  @media (max-width: 991.98px) {
    height: 10rem;
  }
  @media (max-width: 575.98px) {
    height: 9rem;
  }
}


.contain-wrapper{
  display: flex;
  justify-content: center;
}

// .parentBadge{
//   position: relative;
// }
// .badge-overlay {
//   position: absolute;
//   left: 0%;
//   top: 0px;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   pointer-events: none;
//   z-index: 100;
//   -webkit-transition: width 1s ease, height 1s ease;
//   -moz-transition: width 1s ease, height 1s ease;
//   -o-transition: width 1s ease, height 1s ease;
//   transition: width 0.4s ease, height 0.4s ease;
// }

.badge-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  transition: width 0.4s ease, height 0.4s ease;
  -webkit-transition: width 1s ease, height 1s ease;
     -moz-transition: width 1s ease, height 1s ease;
     -o-transition: width 1s ease, height 1s ease;
}

.badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  transform: translateX(30%) translateY(0%) rotate(45deg);
}

.badge.new {
  background-color: #24613e;
}
.nodataTxt{
  text-align: center;
  margin-top: 4rem;
}