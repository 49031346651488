.emptyCart {
  margin-top: 1rem;
  background-color: white;
  padding: 3rem 1rem;
}
.mainLogoWide {
  border-radius: 0.25rem !important;
  height: 6.5rem;
}
.locationHeaderCenter {
  text-align: center;
}
.LocationModalLine {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.NavBox {
  h5,
  .NavBoxWorkWide ,button{
    background-color: white;
    cursor: pointer;
    color: black ;
    margin-right: 1rem;
    padding: 0.6rem 1rem;
    border-radius: .5rem;
    font-size: 16px;
    transition: all 0.5s ease-in;
    font-family: 'Roboto', sans-serif !important;

  }
}
.navBox2{
    button{
        color:black !important
    }
}
.startForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cardItem{
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  .cardItemImg{
    margin-right: 1rem;
  }

}

.mainLogoWide{
  height:70px
}
.mainLogosm{
  height:70px;
  margin-top: 10px;
}
.totalPrice{
  background-color: white;
  padding:20px;
  font-weight: 500;
  border:1px solid #f2f2f2;
  margin:0px 10px
}