.single-rate-container{
    display: flex;
    flex-direction: column;
    margin: 11px 0px;
    justify-content: center;
    align-items: center;
}

.formWrapper{
    margin: 0 auto;
    // min-width: 86%;
}

.rate-table {
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.single-rate{
    width: 75%;
    border: 1px solid;
    border-radius: 30px;
    margin-top: 10px;
}

.sec-rate-container{
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}





