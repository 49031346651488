.error-page {
    position: relative;
  
    .MuiButton-contained {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  