.footerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
  background-color: #f6f3f2;
  padding: 24px 48px;
  .footerTxt {
      
    margin: 0 !important;
    padding: 0;
    font-size: 0.875rem;
}
a {
      color: #8898aa;
  }
}
