.CartWrapper {
  margin-top: 11rem;
  .form {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 0.625rem;
    border: 0.0625rem solid rgba(0, 0, 0, 0.05);
    .titleContainer {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      border-bottom: 0.0625rem solid #e9ecef !important;
      h3 {
        text-align: center;
        margin-bottom: 0.9rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 0.5rem;
        font-size: 24px;
      }
    }
    .itemsMainWrapper {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .SideImageContainer {
        padding-left: 20px;
        padding-top: 15px;
        margin-left: 20px;
        margin-bottom: 10px;
        .SideImageContainerImg {
          border-radius: 0.625rem;
          width: 100px;
          height: 100px;
        }
      }
      .TextContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
      }
      .Buttonsontainer {
        margin-top: 10px;
      }
      .RadioInputContainer,
      .selectContainer,
      .InputContainer {
        margin: 1rem 3rem 2rem 3rem;
        .inputButtonContainer{
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
      }
      .telcontainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 100%;
        span {
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 0.5rem;
          font-size: 14px;
        }

        .intlTel {
          height: 2rem;
          width: 100%;
        }
      }
    }
    .listMainWrapper{
      .listMainWrapperMainTxt{
        text-align: center;
      }
      .listMainWrapperMainList{
        border: 1px solid #d2d6dc;
        padding: 20px;
      }
      .ListItem{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .ListItemLastTxt{
          font-weight: bold;
        }
      }
    }
    .CheckBoxContainer{
      margin-top: 20px;
    }
  }
  .text-center{
    margin-left: 3rem;
  }
}
