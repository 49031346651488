.scaling-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    .typeWrite{
        margin-top: 2rem;
        .Typewriter__wrapper{
            color:#f07950;
        }
    }
  }
  
  .scaling {
    animation-name: scaleAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    border-radius: 20px;
  }
  
  @keyframes scaleAnimation {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  }
  