.headerNav{
    background-color: white;
    height: 37vh;
    background-image: url("https://app.talabhub.com/uploads/settings/2a08d334-68c9-4ca5-9132-82073cf3e951_cover.jpg");
    background-size: cover;
    background-position: center top;
    position: absolute;
    width: 100%;
    z-index: -1;
    margin-top: -11rem;
}
.RatingWrapper{
    margin-top: 11rem;
    display: flex;
    justify-content: center;
    .form{
        background: #ffffff ;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
        transition: all .15s ease;
        // width: 50rem;
        padding-bottom: 2rem;
        border-radius: 0.625rem;

        text-align: center;
        h3, h5, h4{
            font-weight: 400;
        }
        .ratingcontainer{
            .ratingcontainerline{
                width: 40%;
                margin: auto;
                margin-top: 1rem;
            }
        }
    }
   
}

@media (max-width: 600px) {
    .phoneinput-wrapper .react-tel-input .form-control {
      width: 65%;
    }
  }
  @media (min-width: 600px) {
    .phoneinput-wrapper .react-tel-input .form-control {
      width: 30vw;
      height:3rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .formcontrol > div {
      width: 50vw;
    }
  }

  .spinner {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
    border-left-color: #000000;
    animation: spin 1s infinite;

    /* Keyframe animation for the spinner */
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}