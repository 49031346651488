@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system,"BlinkMacSystemFont","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
h3 {
  font-size: 1.75rem;
}
h5 {
  font-size: 1.25rem;
}
a {
  color: inherit;
  text-decoration: none;
  font-family: sans-serif;
  text-transform: none;
}
.MuiGrid2-container{
  margin: 0 !important;
}
h3,h5 , p{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
p{
  margin-top: .5rem;
  margin-bottom: .5rem ;
}