.headerNav{
    background-color: white;
    height: 37vh;
    background-image: url("https://app.talabhub.com/uploads/settings/2a08d334-68c9-4ca5-9132-82073cf3e951_cover.jpg");
    background-size: cover;
    background-position: center top;
    position: absolute;
    width: 100%;
    z-index: -1;
    margin-top: -11rem;
}
.ReservationWrapper{
    margin-top: 11rem;
    display: flex;
    justify-content: center;
    .form{
        background: #ffffff ;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
        transition: all .15s ease;
        // width: 50rem;
        padding-bottom: 2rem;
        border-radius: 0.625rem;

        text-align: center;
        h3{
            font-weight: 400;
        }
    }
    .formcontrol{
        margin-top: 1rem;
        // width: 100%;
        // margin-left: 5rem;
    }
    .telcontainer{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        text-align: start;
    width: 70%;
        span{
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: .5rem;
            font-size: 14px;
        }
   
    .intlTel{
        height: 3rem;
        font-size: 16px;
        width:13rem;
        
    }
}
.Simplecontainer{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    text-align: start;
    span{
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: .5rem;
        font-size: 14px;
    }
}
.counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    .countertxt{
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 20px;
    }
   
}
}
